<template>
  <a
    class="cms-media-file"
    :href="href"
    @click.prevent="downloadFile"
    target="_blank"
  >
    <ui-item
      class="ui-clickable"
      :text="text"
      :secondary="secondary"
      :icon="icon"
    ></ui-item>
  </a>
</template>

<script>
import { UiItem } from '@/modules/ui/components';
import {download} from '/src/libraries/phidias.js/lib/File/download.js';

export default {
  name: 'cms-media-file',
  components: { UiItem },
  props: {
    href: {
      type: String,
      required: false,
      default: '#'
    },
    text: {
      type: String,
      required: false,
      default: 'Sin título'
    },
    secondary: {
      type: String,
      required: false,
      default: ''
    },
    icon: {
      type: String,
      required: false,
      default: 'mdi:file'
    },
    name: {
      type: String,
      required: false,
    },
    size: {
      type: Number,
      required: false,
    },
    mimetype: {
      type: String,
      required: false,
    }
  },
  computed: {
    readyFile() {
      return {name: this.name, size: this.size, mimetype: this.mimetype, url: this.href}
    }
  },
  methods: {
    downloadFile() {
      download(this.readyFile)
    }
  }
};
</script>

<style lang="scss">
.cms-media-file {
  display: block;
}
</style>